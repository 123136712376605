import _ from "lodash";
import React, { Fragment } from "react";


export const ContactsButtons = (props) => {
  const { data } = props;
  // const { env } = useSelector((state) => state.frontend);
  // const { landings = {} } = env;
  // const landingData = { ...landings[props.type] };
  const className = `${props.color} text-decoration-none`;
  return (
    <div className="large-header d-flex mb-2">
      <div className="ml-auto"></div>
      {data.telegram ? (
        <div className="mx-4">
          <a
            href={data.telegram}
            {...{ className }}
            target="_blank"
            data-id="telegram"
            // onClick={metrikaTargetClick}
          >
            <i className="fab fa-telegram-plane" />
          </a>
        </div>
      ) : null}

      {data.email ? (
        <div className="mx-4">
          <a
            href={`mailto:${data.email}`}
            {...{ className }}
            target="_blank"
            data-id="email"
            // onClick={metrikaTargetClick}
          >
            <i className="far fa-envelope" />
          </a>
        </div>
      ) : null}
      <div className="mr-auto"></div>
    </div>
  );
};
