import _ from "lodash";
import { RSAA } from "redux-api-middleware"; // RSAA = '@@redux-api-middleware/RSAA'
import { accessToken, sessionToken } from "../token";
import { applyUrlParams } from "../init";

export const { FRONTEND_API_URL } = process.env;

export const SUCCESS = "FRONTEND_API_URL_SUCCESS";
export const FAILURE = "FRONTEND_API_URL_FAILURE";
export const REQUEST = "FRONTEND_API_URL_REQUEST";

const { BUILD_VERSION } = process.env;

export default class {
  constructor(initState = {}) {
    this.states = {};
    this.actions = {};
    this.middlewares = {};
    this.url_states = {}; // стейты в контексте конкретной ссылки
    this.initState = initState;
  }

  get(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "GET", { success, failure, request }, this);
  }

  post(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "POST", { success, failure, request }, this);
  }

  patch(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "PATCH", { success, failure, request }, this);
  }

  put(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "PUT", { success, failure, request }, this);
  }

  auto(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "AUTO", { success, failure, request }, this);
  }

  upload(endpoint, { success, failure, request } = {}) {
    return createApi(endpoint, "POST", { success, failure, request }, this, {
      upload: true,
    });
  }

  // создать редюсер, который в контексте своего объекта будет вызывать
  reducer() {
    const { states, initState } = this;
    return (state = initState, action) => {
      if (_.isFunction(_.get(states, action.type))) {
        return states[action.type](state, action.payload);
      }
      return state;
    };
  }

  middleware() {
    const { states, actions, middlewares } = this;
    return (store) => (next) => (action = { type: "" }) => {
      const result = next(action);
      if (_.isFunction(_.get(middlewares, action.type))) {
        // console.log ({middlewares, type: action.type})
        // тут может быть баг: если повторно вызван этот же запрос, пока другой не завершился
        // с указанием другой функции миддливара
        // вилка для того, чтобы вернуть в случае ошибки оригинальный response со стороны сервера
        const payload = _.get(action, "payload.response") || _.get(action, "payload");
        middlewares[action.type](payload);
        // удалим middleware после его запуска
        // delete middlewares[action.type];
      }
      return result;
    };
  }
}

export const defaults = {
  success(state) {
    return { ...state };
  },
  request(state) {
    return { ...state, _last_request: new Date() };
  },
  failure(state, result) {
    return {
      ...state,
      _last_error: _.get(result, "response.message") || _.get(result, "statusText"),
    };
  },
};

// генератор вызовов
export function createApi(url, method = "GET", props, reducer, options = {}) {
  const { success, failure, request } = _.omit(props);
  const { postfix = Math.random() } = options;
  let ON_SUCCESS = `${method} ${url}/${SUCCESS}_${postfix}`;
  reducer.states[ON_SUCCESS] = success || defaults.success;

  let ON_FAILURE = `${method} ${url}/${FAILURE}_${postfix}`;
  reducer.states[ON_FAILURE] = failure || defaults.failure;

  let ON_REQUEST = `${method} ${url}/${REQUEST}_${postfix}`;
  reducer.states[ON_REQUEST] = request || defaults.request;

  const types = [ON_REQUEST, ON_SUCCESS, ON_FAILURE];

  Object.assign(reducer.url_states, {
    [url]: { ON_REQUEST, ON_SUCCESS, ON_FAILURE },
  });

  /*
  const applyUrlParams = (url, params, query) => {
    // todo replace keys in url by params
    _.each(params, (value, key) => {
      url = url.replace(`{${key}}`, value);
    });
    return  url + "?" + qs.stringify(query);
  };
  */

  const isUpload = options.upload;

  return (req = {}, res = {}) => {
    const { body, query, params, headers = {} } = req;

    Object.assign(headers, {
      "API-Request-Id": Date.now() + "-" + Math.random(),
      "Build-Version": BUILD_VERSION,
      "Current-Location": document.location.href,
      // "CloClo-Context-Segment": CONTEXT_SEGMENT,
    });
    // если это не загрузка файла, то добавим фиксированный тип контента
    if (!isUpload) {
      headers["Content-Type"] = "application/json";
    }

    const session = localStorage.getItem(sessionToken);
    if (session) {
      _.merge(headers, { [sessionToken]: session });
    }

    const token = localStorage.getItem(accessToken);
    if (token) {
      _.merge(headers, { Authorization: token });
    }
    let route = {};

    if (method === "AUTO") {
      route = _.get(window.treeepay_routes, url);
      if (!route) {
        throw `no auto route ${url}`;
      }
    }
    // /*
    route.path && (url = route.path);
    route.method && (method = route.method);
    // */
    const endpoint = `${FRONTEND_API_URL}${applyUrlParams(url, params, query)}`;

    // вот тут следует
    reducer.middlewares[ON_SUCCESS] = res.onSuccess;
    reducer.middlewares[ON_FAILURE] = res.onFailure;
    reducer.middlewares[ON_REQUEST] = res.onRequest;
    // console.log (new Date, 'do rsaa', {endpoint, method, headers, ON_SUCCESS, 'mw': reducer.middlewares[ON_SUCCESS]});

    /*
        if (isUpload) {
            reducer.middlewares[ON_PROGRESS] = res.onProgress;
        }
        // */

    return {
      [RSAA]: {
        endpoint,
        headers,
        method,
        body: isUpload ? body : JSON.stringify(body),
        types,
        credentials: "include",
      },
    };
  };
}
