import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
// import Sitemap from "./sitemap";

import Header from "components/common/headers/header";
import Footer from "components/common/footers/footer";

import Layout from "./views/layout";

import Index from "./views";

import Account from "./views/account";

import ChannelsList from "./views/channels";
import ChannelPage from "./views/channels/page";
import ChannelsLanding from "./views/channels/landing";
import MarketingLanding from "./views/marketing/landing";
import AboutLanding from "./views/about";
import Live from "./views/live";
import SpecialsIndex from "./views/specials";

import Content from "./views/content";
import Playlist from "./views/playlist";

// import Feedback from "./views/feedback";

const LayoutView = (props) => {
  return (
    <Layout>
      <Header />
      {props.children}
      <Footer />
    </Layout>
  );
};

const RoutesMap = () => {
  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <LayoutView>
            <Index />
          </LayoutView>
        }
      />
      <Route
        path={`/account/*`}
        element={
          <LayoutView>
            <Account />
          </LayoutView>
        }
      />

      <Route
        path={`/join`}
        element={
          <LayoutView>
            <ChannelsLanding />
          </LayoutView>
        }
      />
      {/* 
      <Route
        path={`/ad`}
        element={
          <LayoutView>
            <MarketingLanding />
          </LayoutView>
        }
      />
       */}

      <Route
        path={`/about`}
        element={
          <LayoutView>
            <AboutLanding />
          </LayoutView>
        }
      />

      <Route
        path={`/specials`}
        element={
          <LayoutView>
            <SpecialsIndex />
          </LayoutView>
        }
      />

      {/* 
      <Route
        path={`/channels`}
        element={
          <LayoutView>
            <ChannelsList />
          </LayoutView>
        }
      />
       */}

      <Route
        path="/offer"
        element={
          <LayoutView>
            <Content />
          </LayoutView>
        }
      />

      {/* <Route
        path="/live"
        element={
          <LayoutView>
            <Live />
          </LayoutView>
        }
      /> */}

      <Route
        path="/:alias"
        element={
          <LayoutView>
            <ChannelPage />
          </LayoutView>
        }
      />
      {/* <Route exact path="/we/*" component={Partners} /> */}
    </Routes>
  );
};

export default RoutesMap;
