import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet-async";
import ReactHtmlParser from "react-html-parser";
import { useTranslate } from "react-redux-multilingual";
import Col3Container from "components/standart/col3Container";
import { SmartImage, scrollTop, keywordsSlice } from "components/standart";

import { frontendApi, fileUrl } from "reducers/api";
import { RoundTelegramButton } from "components/standart/mediaButtons";

import { SubscribeBlock, GreyButton } from "../casts/cast";
import CastsRecomendation from "../casts/recomendation";

const { LIVESTREAM_URL } = process.env;
const { api } = frontendApi.actions;

const Live = (props) => {
  const translate = useTranslate();
  const [lastCast, setLastCast] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const playerId = "player_live";

  useEffect(() => {
    scrollTop();

    props.getTopCasts(
      { query: { topOnly: true, lastOnly: true, limit: 1 } },
      {
        onSuccess([body]) {
          setLastCast(body);
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
        },
      }
    );
    // setTimeout(() => { }, 1000);
  }, []);

  const { env } = useSelector((state) => state.frontend);
  const { meta = {}, settings = {} } = env;

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.live || {};

  return lastCast ? (
    <>
      {/*SEO Support*/}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="og:keywords" content={keywords} />
      </Helmet>
      {fileId ? (
        <Helmet>
          <meta property="og:image" content={fileUrl(fileId, "image.jpg")} />
          <meta property="image" content={fileUrl(fileId, "image.jpg")} />
        </Helmet>
      ) : null}

      <section className="m-0 p-0">
        <div className="container py-0 text-center">
          <Col3Container>
            <TranslationsLargeBlock cast={lastCast} />
            {/* */}

            <div id={playerId} style={{ width: "100%", height: "360px" }}>
              {/* 
              <ReactPlayer
                url={LIVESTREAM_URL}
                playsinline
                width="100%"
                // height="100%"
                controls={true}
                // light={true}
                config={{
                  file: {
                    forceHLS: true,
                  },
                }}
              /> 
              // */}
            </div>

            <div className="py-4">
              <CastsRecomendation
                title={translate("now_listening")}
                query={{ lastTopChannelsOnly: true }}
                skipId={lastCast?._id}
                showMore={{ alias: "" }}
              />
            </div>
          </Col3Container>
        </div>
      </section>
    </>
  ) : null;
};

export const TranslationsLargeBlock = (props) => {
  const { cast } = props;
  const translate = useTranslate();
  const navigate = useNavigate();
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  return settings.translationFileId ? (
    <div className="pt-2">
      <div className="row">
        <div className="col-3"></div>
        <div className="col">
          <div className="position-relative ">
            <div className="text-center position-relative rounded rounded-xl border border-1">
              <div
                className="block-squared -d-md-none"
                // style={backgroundCover(fileUrl(settings.translationFileId, "image.jpg"))}
              ></div>
              <div className="d-flex w-100 h-100 position-absolute" style={{ left: 0, top: 0 }}>
                <SmartImage
                  src={fileUrl(settings.translationFileId, "image.jpg")}
                  style={{ objectFit: "cover", overflow: "hidden" }}
                  className="rounded rounded-xl"
                />
              </div>
            </div>
            {/* <div className="position-absolute w-100 cover-text-block d-flex rounded rounded-xl">
          <div className="mt-auto w-100">
            <div className="pb-3" />
          </div>
        </div> */}
          </div>
        </div>
        <div className="col-3"></div>
      </div>

      <div className="-position-absolute w-100 -cover-text-block d-flex -text-white rounded rounded-xl">
        <div className="mt-auto w-100 text-center">
          <div className="pt-3" />

          <div className={`large-header mb-1 -mx-3 text-truncate`}>
            {translate("translation_title")}
          </div>

          <div className="pb-2 d-flex flex-wrap align-content-center justify-content-center">
            <div className="m-1">
              <small
                className="py-1 px-2 rounded"
                style={{ backgroundColor: "rgb(220, 53, 69)", color: "white" }}
              >
                <i className="fal fa-signal-stream mr-1" />
                {translate("translation_time")}
              </small>
            </div>

            <GreyButton text={translate("ai_reading")} onClick={() => navigate("/about")} />
          </div>

          <div className={`px-2 text-center mb-1 player-text-3}`}>
            {keywordsSlice(cast?.keywords)}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export const TranslationsLinkBlock = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  return settings.translationFileId ? (
    <Link
      to={"/live"}
      className="text-center"
      // target="_blank"
      // href={`${settings.telegramChannel}?livestream`}
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <div className="position-relative ">
        <div className="fixed-corner rounded-xl-one" style={{ zIndex: 99 }}>
          <div className="text">
            <small>
              <i className="fal fa-thumbtack" />
            </small>
          </div>
        </div>

        <div className="text-center position-relative rounded rounded-xl border border-1">
          <div
            className="block-squared -d-md-none"
            // style={backgroundCover(fileUrl(settings.translationFileId, "image.jpg"))}
          ></div>
          <div className="d-flex w-100 h-100 position-absolute" style={{ left: 0, top: 0 }}>
            <SmartImage
              src={fileUrl(settings.translationFileId, "image.jpg")}
              style={{ objectFit: "cover", overflow: "hidden" }}
              className="rounded rounded-xl"
            />
          </div>
        </div>

        {/* <div className="position-absolute w-100 cover-text-block d-flex rounded rounded-xl">
          <div className="mt-auto w-100">
            <div className="pb-3" />
          </div>
        </div> */}
      </div>
      <div className="-position-absolute w-100 -cover-text-block d-flex -text-white rounded rounded-xl">
        <div className="mt-auto w-100 text-center">
          <div className={`standart-header font-weight-bold mt-1 mb-1 -mx-3 text-truncate`}>
            {translate("translation_title")}
          </div>
          {/* 
            <div className="px-2 mb-2">
              {cast.keywords.slice(0, 3).join(". ")}
              {"..."}
            </div>
            */}
          <div className="pb-1 -text-secondary">
            <small
              className="py-1 px-2 rounded"
              style={{ backgroundColor: "rgb(220, 53, 69)", color: "white" }}
            >
              <i className="fal fa-signal-stream mr-1" />
              {translate("translation_time")}
            </small>
          </div>
        </div>
      </div>
    </Link>
  ) : null;
};

const mapStateToProps = (state) => ({
  locales: state.locales,
});
export default connect(mapStateToProps, {
  getTopCasts: api.get("/casts"),
})(Live);
