import _ from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { userApi } from "reducers/api";
import { SocketsController } from "reducers/api/sockets";
import { usePageVisibility } from "react-page-visibility";

const { session } = userApi.actions;

const UseCategoriesUpdates = (props) => {
  const isVisible = usePageVisibility();

  const [socketUpdated, setSocketUpdated] = useState(null);
  const { session } = useSelector((state) => state.user);

  const onmessage = function () {
    setSocketUpdated(new Date());
  };
  const socketsRef = useRef(new SocketsController("/categories", onmessage));

  const getUnreadedAmount = () => {
    if (session.account) {
      // props.getUser();
    }
  };

  const connectSocket = () => {
    socketsRef.current.connect();
    setSocketUpdated(new Date());
  };

  const destroySocket = () => {
    socketsRef.current.safeClose();
  };

  useEffect(() => {
    getUnreadedAmount();
  }, [socketUpdated]);

  useEffect(() => {
    try {
      if (isVisible) {
        connectSocket();
      } else {
        destroySocket();
      }
    } catch (e) {
      console.error(e);
    }
  }, [isVisible]);

  return <Fragment/>;
};

export default connect(null, {
  ...session.actions,
  // checkNewMessages: api.get("/account/favorites/unread"),
})(UseCategoriesUpdates);
