import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import nl2br from "react-nl2br";
import ReactHtmlParser from "react-html-parser";
import Col3Container from "components/standart/col3Container";
import { ChannelBlockSquare } from "components/standart/channelBlock";
import { frontendApi } from "reducers/api";
import { LoadingIcon, useWindowSize } from "components/standart";
import { useDemoPlay } from "components/common/player/refs/demo";

import { Link, useNavigate } from "react-router-dom";

const { api } = frontendApi.actions;

const CastsSearchModal = (props) => {
  const { navi } = props;
  const translate = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { demoPlayRef } = useDemoPlay();
  const timeoutRef = useRef(null);
  const { session } = useSelector((state) => state.user);
  const { env } = useSelector((state) => state.frontend);

  const { landings = {} } = env;
  const landingData = { ...landings.channels };
  const { title = "", content = "", isHtml } = { ...landingData?.intro };

  const [channels, setChannels] = useState([]);
  const [channelsFiltered, setChannelsFiltered] = useState([]);
  const [channelsGroups, setChannelsGroups] = useState({});
  const [favoritesId, setFavoritesId] = useState([]);
  const [specialsCasts, setSpecialsCasts] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(props.categoryId);
  const [categories, setCategories] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isFavorites, setFavorites] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isReady, setIsReady] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(null);

  const categoriesDivId = "categories_block";
  const size = useWindowSize();

  const limit = 24;

  const [containerHeight, setCointainerHeight] = useState(80);
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      const defineContainerHeight = () => {
        const { top } = containerRef.current.getBoundingClientRect();
        setCointainerHeight(size.height - Math.round(top) - 30);
      };
      setTimeout(defineContainerHeight, 500);
    }
  }, [size, containerRef.current]);

  // отреагируем на значения учетной записи
  useEffect(() => {
    // setFavoritesId(session.account ? session.channelsId : env.playlistDefaults);
  }, [session.account]);

  useEffect(() => {
    demoPlayRef.current.hideDemoChannel();

    props.getSpecials(
      { query: { lastOnly: true } },
      {
        onSuccess(body) {
          setSpecialsCasts(body);
        },
      }
    );

    props.getCategories(
      {},
      {
        onSuccess(body) {
          setCategories(body);
          setIsReady(true);
          // setTimeout(restoreCategoriesView, 100)
        },
      }
    );

    props.modalRef.current.addEventListener("scroll", handleScroll);
    timeoutRef.current = setTimeout(handleScroll, 1000);
    return () => {
      clearTimeout(timeoutRef.current);
      // props.modalRef.current.removeEventListener("scroll", handleScroll);
    };

    return () => {
      // history.replace('?')
    };
    // loadChannels(0);
  }, []);

  const searchRef = useRef();

  const handleScroll = (event) => {
    let number = props.modalRef.current.scrollTop;
    setShowScrollTop(number > searchRef.current.offsetTop);
  };

  useEffect(() => {
    setChannelsFiltered(
      _.filter(channels, (channel) => {
        if (_.trim(searchName)) {
          const search = _.trim(searchName)
            .replace(/[@,-,+,!,#,%,&]/gi, "")
            .split(" ")
            .join("|"); // уберем лишние символы
          const regexp = new RegExp(search, "i");
          return (
            _.toString(channel.name).search(regexp) >= 0 ||
            _.toString(channel.alias).search(regexp) >= 0 ||
            _.toString(channel.searchName).search(regexp) >= 0
          );
        } else {
          return true;
        }
      })
    );
  }, [channels, searchName]);

  useEffect(() => {
    setChannelsGroups(_.groupBy(channelsFiltered, "categoryId"));
  }, [channelsFiltered]);

  useEffect(() => {
    if (isReady) {
      loadChannels(0, true);
    } else {
      setChannels([]);
    }
  }, [isReady]);

  const goToCategoryHandler = (category) => {
    setSearchName(""); // сбросим поиск, если он был
    // const scrollCoords
    // console.log("category block is", { categoryBlock });
    setTimeout(() => {
      const categoryBlock = document.getElementById(category._id);
      props.modalRef.current.scrollTo({ top: categoryBlock.offsetTop, behavior: "smooth" });
    }, 10);
  };

  const setCategoryHandler = (category) => {
    setSearchName(""); // сбросим поиск, если он был
    setCurrentCategory(category._id);
  };

  const scrollTopHandler = () => {
    props.modalRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const loadChannels = useCallback(() => {
    const query = {
      // limit,
      // offset,
      name: searchName,
    };

    props.getChannels(
      { query },
      {
        onSuccess(body) {
          // const channelsId = _.map(body, (channel) => channel._id);
          // if (session.account) checkForNew(channelsId);
          setChannels(body);
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
          clearTimeout(searchTimeout.current);
        },
      }
    );
  }, [searchName, channels]);

  const searchTimeout = useRef();

  const searchChannelHandler = ({ target }) => {
    setCurrentCategory(null);
    setSearchName(target.value);
  };

  const commonButtonClass =
    "btn btn-rounded btn-sm -font-weight-normal -standart-header border-secondary mr-2 mb-2";
  const activeButtonClass = "btn-dark active";

  const channelsBlock = "channels-block";
  const [height, setHeight] = useState(80);

  const columnsClassName = "col-4 col-lg-3 px-2 mb-3";

  return categories ? (
    <div className="position-relative">
      <Col3Container>
        <div id={channelsBlock}>
          {/* 
          <div className="text-center large-header mb-3">{nl2br(title)}</div>
          <div className="text-center standart-header mb-3">
            {isHtml ? ReactHtmlParser(content) : nl2br(content)}
          </div>
            */}
          <div
            ref={containerRef}
            id={`search-data`}
            style={{ height: `${containerHeight}px`, overflowY: "auto", overflowX: "hidden" }}
          >
            <div
              id={categoriesDivId}
              className="-list-group -list-group-horizontal -text-nowrap -overflow-auto favorites-list position-relative mb-3"
            >
              <span
                className={`${commonButtonClass} ${!currentCategory ? activeButtonClass : ""}`}
                onClick={() => setCategoryHandler({ _id: null })}
                // onClick={() => goToCategoryHandler(category)}
              >
                {"Все"} {`(${_.size(channels) + _.size(categories)})`}
              </span>

              {categories.map((category, index) => {
                const addClass = currentCategory === category._id ? activeButtonClass : "";
                return (
                  <span
                    className={`${commonButtonClass} ${addClass}`}
                    key={index}
                    onClick={() => setCategoryHandler(category)}
                    // onClick={() => goToCategoryHandler(category)}
                  >
                    {category.name} {`(${_.size(channelsGroups[category._id]) + 1})`}
                  </span>
                );
              })}
            </div>

            <input
              className="form-control rounded mb-4"
              value={searchName}
              ref={searchRef}
              placeholder={translate("channels_search")}
              onChange={searchChannelHandler}
            />

            {isLoading && (
              <div className="col-12 text-center py-3">
                <LoadingIcon />
              </div>
            )}

            <div className="mt-1">
              {_.trim(searchName) ? (
                <div>
                  <div className="row px-2">
                    {_.map(channelsFiltered, (channel, index) => {
                      return (
                        <ChannelBlockSquare
                          channel={{
                            ...channel,
                            name: `${channel.name}`,
                          }}
                          // showFavorite={true}
                          size="medium"
                          className={columnsClassName}
                          key={index}
                          onClick={() => {
                            props.closeHandler();
                            // navigate(`/${channel.alias}`);
                            navigate(`/${channel.alias}?castId=${channel.castId}`);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div>
                  {specialsCasts.length ? (
                    <div>
                      <div className="text-center large-header pt-2 mb-3">
                        {translate("specials_casts")}
                      </div>
                      <div className="row px-2">
                        {_.map(specialsCasts, (cast, index) => {
                          return (
                            <ChannelBlockSquare
                              channel={{
                                ...cast,
                                name: `${cast.category.name}`,
                              }}
                              size="medium"
                              className={columnsClassName}
                              onClick={() => {
                                props.closeHandler();
                                navigate(`/specials?specialId=${cast._id}`);
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                  {_.map(categories, (category) => {
                    const categoryId = category._id;
                    const categoryChannels = channelsGroups[categoryId];
                    return !currentCategory || currentCategory === category._id ? (
                      <div key={categoryId}>
                        <div className="text-center large-header pt-2 mb-3" id={category._id}>
                          {category.name}
                        </div>
                        <div className="row px-2">
                          <ChannelBlockSquare
                            channel={{
                              ...category,
                              alias: `${category.alias}`,
                              name: `${category.castTitle || category.name}`,
                            }}
                            isCategory={true}
                            // showFavorite={true}
                            size="medium"
                            className={columnsClassName}
                            onClick={() => {
                              props.closeHandler();
                              // navigate(`/${category.alias}-top`);
                              navigate(`/?castId=${category.castId}`);
                            }}
                          />

                          {_.map(categoryChannels, (channel, index) => {
                            return (
                              <ChannelBlockSquare
                                channel={{
                                  ...channel,
                                  name: `${channel.name}`,
                                }}
                                // showFavorite={true}
                                size="medium"
                                className={columnsClassName}
                                key={index}
                                onClick={() => {
                                  props.closeHandler();
                                  navigate(`/${channel.alias}?castId=${channel.castId}`);
                                }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              )}
            </div>

            {isFavorites ? null : <RequestChannelMessage />}
          </div>
          {/* 
          <div
            className="position-fixed d-block py-2 text-center"
            style={{
              zIndex: "1099",
              width: "100%",
              height: `${height}px`,
              left: `0px`,
              bottom: `${navi.bottomSpaceHeight}px`,
            }}
          >
            <Col3Container>
              {showScrollTop ? (
                <div className="row">
                  <div className="col-3 p-0"></div>
                  <div className="col  text-center p-0">
                    <button
                      className="btn btn-outline  standart-header"
                      style={{ border: "2px solid black" }}
                      onClick={() => {
                        props.closeHandler();
                      }}
                    >
                      <i className="fas fa-times mr-2" />
                      {translate("modal_close")}
                    </button>
                  </div>
                  <div className="col-3 text-center p-0">
                    <button
                      className="btn btn-outline standart-header px-3"
                      style={{ border: "2px solid black" }}
                      onClick={scrollTopHandler}
                    >
                      <i className="fa fa-chevron-up" />
                    </button>
                  </div>
                </div>
              ) : null}
            </Col3Container>
          </div>
           */}
        </div>
      </Col3Container>
      {/* 
      <div
        style={{
          marginBottom: `${navi.bottomSpaceHeight + height}px`,
        }}
      ></div>
      */}
    </div>
  ) : null;
};

const RequestChannelMessage = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const translate = useTranslate();
  const { telegram } = env.settings;
  return (
    <div className="text-center mt-3">
      <div>{translate("request_channel_text")}</div>
      <a
        href={"/join"}
        className="btn btn-outline px-3 my-4 standart-header"
        style={{ border: "2px solid black" }}
        // target="_blank"
      >
        {/* <i className="fab fa-telegram-plane mr-2" /> */}
        {translate("request_channel_button")}
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, {
  getChannels: api.get("/casts/channels"),
  getSpecials: api.get("/specials"),
  getCategories: api.get("/casts/categories"),
})(CastsSearchModal);
