import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { metrikaTargetClick } from "services";
import { scrollTop } from "components/standart";
import { ContactsButtons } from "components/standart/contactsButtons";
import { SmartImage, LiveIndicator } from "components/standart";
import { fileUrl } from "reducers/api";
import { Link } from "react-router-dom";
import nl2br from "react-nl2br";

const Footer = (props) => {
  const { translate } = props;

  const { settings = {} } = props.env || {};

  const className = "text-white mx-2";
  return (
    <footer>
      <section className="bg-black">
        <div className="container">
          <div className="standart-header text-center text-white">
            <div className="mx-auto my-auto d-flex justify-content-center">
              {settings.logoFileId ? (
                <div className="text-right my-auto mr-2 pt-0">
                  <Link to={"/"}>
                    <SmartImage
                      src={fileUrl(settings.logoFileId, "preview.jpg")}
                      style={{ width: "29px", height: "29px" }}
                      className="rounded rounded-circle"
                    />
                  </Link>
                </div>
              ) : null}
              <div className="text-left my-auto">
                <Link
                  to="/"
                  onClick={scrollTop}
                  className="d-block text-white text-decoration-none text-lowercase mb-1"
                >
                  <div style={{ fontSize: "1.4em", fontFamily: "Arial Black", fontWeight: "bold" }}>
                    {translate("sitename")}
                  </div>
                  {/* 
                  <div
                    style={{ fontSize: "0.69em", fontFamily: "Arial", marginTop: "-6px" }}
                    className="text-lowercase"
                  >
                    {translate("sitename_helper")}
                  </div>
                   */}
                </Link>
              </div>
            </div>
            <div className="mb-2">
              {/* 
              {settings.footerText ? (
                <div className="pt-3">{nl2br(settings.footerText)}</div>
              ) : null}
            */}
            </div>
            <div className="pt-2">
              <Link to={"/"} {...{ className }}>
                {translate("menu_index")}
              </Link>

              <Link to={"/join"} {...{ className }}>
                {translate("menu_channels")}
              </Link>
              <Link to={"/about"} {...{ className }}>
                {translate("menu_about")}
              </Link>
              {/* 
              <Link to={"/ad"} {...{ className }}>
                {translate("menu_adverts")}
              </Link>
              */}
            </div>
            <div className="pt-2">
              <a
                target="_blank"
                href={`${settings.telegramChannel}?livestream`}
                className="text-white py-1"
                data-id="telegram-translation-footer"
                onClick={metrikaTargetClick}
              >
                {translate("translation_title")}
              </a>
              <span className="mx-1"/>
              <LiveIndicator />
            </div>
            <div className="pt-2">
              <a
                target="_blank"
                href="/offer"
                className="text-white py-1"
                data-id="offer"
                onClick={metrikaTargetClick}
              >
                {translate("offer")}
              </a>
            </div>
            <div className="pt-4" />
            <div className="mb-3 text-white">{translate("support_contacts")}</div>

            <ContactsButtons data={settings} color="text-white" />
          </div>
        </div>
        {/* <div className="d-block d-lg-none mobile-menu-space">&nbsp;</div> */}
      </section>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
});

export default connect(mapStateToProps, null)(withTranslate(Footer));
