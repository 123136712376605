import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { iconFontSize } from "constants/index";
import FavoritesIndicator from "components/standart/indicator";

import { useTranslate } from "react-redux-multilingual";
import Auth from "views/auth";
import Col3Container from "components/standart/col3Container";
import { metrikaTargetClick } from "services";

const UserLogin = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { sessionStore } = props;
  const [loginModal, setLoginModal] = useState(false);

  //*
  useEffect(() => {
    if (loginModal) {
      if (sessionStore?.account) {
        setLoginModal(false);
        // navigate("/account");
      }
    }
  }, [sessionStore, loginModal]);
  // */
  const style = { fontSize: iconFontSize };
  return (
    <>
      {sessionStore.account ? (
        <div className="mt-1 position-relative" onClick={() => navigate("/account")}>
          {/* 
          <div className="position-absolute" style={{ top: -7, right: -10 }}>
            <FavoritesIndicator />
          </div>
          */}

          <Link
            to={`/account`}
            className="text-black text-decoration-none -standart-header relative"
            data-id="account"
            onClick={metrikaTargetClick}
            style={{ ...style }}
          >
            {props.children || (
              <>
                <i className="far fa-user-check" />
                {/* <AccountEmoji /> */}
                {/*
                <div className="partner-logos">
                  <span className="partner micro" style={{ fontSize: "20px" }}>
                    <img src="/white.png" className="-rounded-circle -border -border-1" />
                    <AccountEmoji />
                  </span>
                </div>
                */}
              </>
            )}
          </Link>
        </div>
      ) : (
        <>
          <div className="mt-1" onClick={() => setLoginModal(true)}>
            {props.children || (
              <a
                className="text-black -standart-header text-decoration-none relative"
                style={{ cursor: "pointer", ...style }}
              >
                <i className="far fa-user" />
                {/* <i className="far fa-heart" /> */}
              </a>
            )}
          </div>

          <Modal
            isOpen={loginModal}
            centered
            toggle={() => setLoginModal(false)}
            wrapClassName={"fullscreen-modal"}
          >
            <ModalHeader toggle={() => setLoginModal(false)}></ModalHeader>
            <ModalBody>
              <Col3Container>
                <div className="text-center">
                  <div className="large-header pb-2">{translate("auth_title")}</div>
                  <Auth />
                </div>
              </Col3Container>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.user.session,
});

export default connect(mapStateToProps, {})(UserLogin);
