import React from "react";
import { useSelector } from "react-redux";
import WhatsAppIcon from "assets/images/whatsapp-logo.svg";
import { metrikaTargetClick } from "services";

const WhatsApp = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { whatsapp } = useSelector((state) => state);
  const { settings = {} } = env || {};

  const url = `https://wa.me/${settings.whatsapp}`;

  return (
    <a
      href={`${url}${whatsapp.message ? "?text=" + whatsapp.message : ""}`}
      target="_blank"
      className="text-black"
      style={{ textDecoration: "none" }}
      data-id="whatsapp"
      onClick={metrikaTargetClick}
    >
      {props.children || (
        <img
          src={WhatsAppIcon}
          style={{ width: "2.2rem", marginTop: "-5px" }}
          alt="whatsapp"
        />
      )}
    </a>
  );
};

export default WhatsApp;
