import _ from "lodash";
import React, { Fragment, useRef, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import AuthModal from "views/auth/modal";
import { useTranslate } from "react-redux-multilingual";

import { Popover, PopoverBody } from "reactstrap";
import { userApi } from "reducers/api";

const { api, session } = userApi.actions;

export const CastsFavoriteButton = (props) => {
  const { channelId, categoryId } = props;
  const { session } = useSelector((state) => state.user);
  const { env } = useSelector((state) => state.frontend);
  const dispatch = useDispatch();
  const [id] = useState(Date.now());
  const [isClickAuth, setClickAuth] = useState(null);
  const [nextFavoriteId, setNextFavoriteId] = useState(null);
  const [favoritesList, setFavoritesList] = useState([]);
  const [dataInFavorite, setDataIsFavorite] = useState(undefined);
  const [result, setResult] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    if (channelId) {
      setDataIsFavorite(favoritesList.includes(channelId));
    } else if (categoryId) {
      setDataIsFavorite(favoritesList.includes(categoryId));
    }
  }, [favoritesList, channelId, categoryId]);

  // снимаем регистрацию по клику при появлении сессии
  useEffect(() => {
    if (session.account) {
      setClickAuth(false);
      if (nextFavoriteId) {
        favoritesHandler({ ...nextFavoriteId })();
      }
      setFavoritesList([...session?.castsChannelsId, ...session?.castsCategoriesId] || []);
    } else {
      setFavoritesList([]);
    }
  }, [session]);

  const tooltipTimeout = useRef();
  useEffect(() => {
    if (result) {
      tooltipTimeout.current = setTimeout(() => setResult(null), 2000);
    }
  }, [result]);

  const favoritesHandler =
    ({ channelId, categoryId }) =>
    (e) => {
      if (e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }
      if (isLoading) return false;

      // dispatch(setPlayerShowChannel(null));

      if (!session.account) {
        setClickAuth(true);
        setNextFavoriteId({ channelId, categoryId });
        return false;
      }
      setNextFavoriteId(null); // отметим, что следющий раз в избранное добавлять ничего не надо
      // если канал уже в избранном, то удалим его оттуда
      if (dataInFavorite) {
        props.deleteCastsFavorite(
          { body: { channelId, categoryId } },
          {
            onSuccess(body) {
              props.getUser(
                {},
                {
                  onSuccess() {
                    setResult("favorites_removed");
                    setLoading(false);
                  },
                }
              );
            },
            onRequest() {
              setLoading(true);
            },
          }
        );
      } else {
        props.addCastsFavorite(
          { body: { channelId, categoryId } },
          {
            onSuccess(body) {
              props.getUser(
                {},
                {
                  onSuccess() {
                    setResult("favorites_added");
                    setLoading(false);
                  },
                }
              );
            },
            onRequest() {
              setLoading(true);
            },
          }
        );
      }
    };

  const iconId = `fav_${id}_${channelId || categoryId}`;
  return (
    <Fragment>
      {props.isButton ? (
        <button
          disabled={props.disabled}
          className={`${props.className}`}
          style={props.style}
          onClick={favoritesHandler({ channelId, categoryId })}
          id={iconId}
        >
          {isLoading || _.isUndefined(dataInFavorite) ? (
            <i
              className={`fas icon fa-spinner-third fa-spin -mr-2  ${props.iconClassName}`}
              //
              // style={props.style}
            />
          ) : (
            <i
              className={`icon ${props.iconClassName} ${
                // dataInFavorite ? "fas fa-heart text-danger" : "far fa-heart"
                dataInFavorite ? "far fa-check text-success" : "far fa-plus"
              } -mr-2`}
              // style={props.style}
            />
          )}
          {props.showText ? (
            <>{dataInFavorite ? translate("favorites_edit") : translate("favorites_add")}</>
          ) : null}
        </button>
      ) : (
        <span
          className={`d-block`}
          // style={{ fontSize: props.fontSize }}
          onClick={favoritesHandler({ channelId, categoryId })}
          id={iconId}
        >
          {isLoading || _.isUndefined(dataInFavorite) ? (
            <i
              className="fas icon fa-spinner-third fa-spin"
              //
              style={props.style}
            />
          ) : (
            <i
              className={`icon ${
                // dataInFavorite ? "fas fa-heart text-danger" : "far fa-heart text-black"
                dataInFavorite ? "far fa-check text-success" : "far fa-plus"
              }`}
              style={props.style}
            />
          )}
        </span>
      )}

      {result ? (
        <Popover fade={true} placement="top" isOpen={true} target={iconId}>
          <PopoverBody>{translate(result)}</PopoverBody>
        </Popover>
      ) : null}

      {isClickAuth && <AuthModal toggleModal={() => setClickAuth(false)} />}
    </Fragment>
  );
};

export default connect(null, {
  addCastsFavorite: api.post("/account/casts-favorites"),
  deleteCastsFavorite: api.post("/account/casts-favorites/delete"),
  ...session.actions,
})(CastsFavoriteButton);
