/**
 * генератор запросов к API статистики
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 */
import _ from "lodash";
import { combineReducers } from "redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import statsReducers from "./reducers";

export default statsReducers;

// типовой редюсер для API
export function reducer(state = {}, action) {
  console.log("do regular reducer", action);
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const statsApiReducer = combineReducers({
  api: reducer,
  ...statsReducers.reducers,
});
// export const adminAPIReducer = reducer;

export const statsAPIMiddleware = (store) => (next) => (action = { type: "" }) => {
  const result = next(action);
  const { payload } = action;

  // если по какой-то причине вернулся 409, то полностью обновим страницу
  // это означает, что умерла кука сессии, или сломался отпечаток клиента
  // if (409 === _.get(payload, "status")) {
  //   document.location.reload();
  // }

  return result;
};

export const middlewares = [statsAPIMiddleware, ...statsReducers.middlewares];
