/**
 * генератор запросов к API статистики
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 */
import _ from "lodash";
import { combineReducers } from "redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import dataReducers from "./reducers";

import { sessionToken, accessToken } from "../token";

export default dataReducers;

// типовой редюсер для API
export function reducer(state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const dataApiReducer = combineReducers({
  api: reducer,
  ...dataReducers.reducers,
});
// export const adminAPIReducer = reducer;

export const dataAPIMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { payload } = action;

  // если по какой-то причине вернулся 401 или 303, то полностью закроем сессию и удалим токены
  if ([401, 303].includes(_.get(payload, "status"))) {
    localStorage.removeItem(sessionToken);
    localStorage.removeItem(accessToken);
    localStorage.removeItem("state");
    document.location.assign("/");
  }

  return result;
};

export const middlewares = [dataAPIMiddleware, ...dataReducers.middlewares];
