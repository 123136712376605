import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";

import Pace from "react-pace-progress";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Import custom components
import { setHeaderSpaceHeight } from "actions";
import { SideMenu, UserLogin, LocalesSelector, SearchModal } from "containers";
// import WhatsApp from "containers/WhatsApp";
import LogoImage from "./common/logo";
import { useTranslate } from "react-redux-multilingual";
import { SmartImage } from "components/standart";
import TelegramButton from "components/standart/mediaButtons";
import { fileUrl } from "reducers/api";

import Col3Container from "../../standart/col3Container";

const Header = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { headerSpaceHeight, setHeaderSpaceHeight } = props;
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    /*
    window.addEventListener("scroll", handleScroll);
    setTimeout(handleScroll, 1000);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    */
  }, []);

  const offsetRef = useRef(0);
  const [scrollOffset, setScrollOffset] = useState(0);
  const translate = useTranslate();
  const scrolledY = useRef([]);

  const limit = 1;

  const clearScrollTimeout = useRef(null);

  const calculateDirection = (number, maxHeight) => {
    const { current } = scrolledY;
    const { length } = current;
    if (length > limit) {
      // если направление больше 0, то крутим вниз, иначе - крутим вверх
      const distance = current[1] - current[0];
      let offset = offsetRef.current;

      // если направление больше 0, то есть крутим вниз, значит нам следует
      // постепенно уменьшать смещение, пока оно не станет превышать минусовую высоту
      // offsetHeight
      if (distance > 0 && number > maxHeight / 2) {
        offsetRef.current = Math.max(offset - distance, -maxHeight);
      } else if (
        (distance < -maxHeight / 4 && offset >= -maxHeight) ||
        (distance < 0 && offset > -maxHeight) ||
        number < maxHeight
      ) {
        // если вращаем вверх, то увеличиваем сдвиг, пока он не станет равным 0 (то есть упрется в потолок)
        // это вычисление небольшой хак, обеспечивающий плавность хода, и корректно восстанавливающий значение
        // в начале координат
        // это выбор лучшей позиции среди абсолютного сдвига по странице, и планируемого смещения
        // если мы находимся близко к верхнему краю, то корректно восстанавливает заголовок в начале координат
        const bestPosition = Math.max(offset - distance / 1.5, -number);
        offsetRef.current = Math.min(bestPosition, 0);
      }
      // увеличим значение сдвига для заголовка
      setScrollOffset(offsetRef.current);
      // применим смещение для позицированирования релевантных блоков относительно указанного сдвига
      setHeaderSpaceHeight(maxHeight + offsetRef.current);
    }
  };

  const handleScroll = (event) => {
    let number =
      window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    const { current: sticky } = stickyHeader; // document.getElementById("sticky");
    const { offsetHeight = 0 } = sticky || {};

    scrolledY.current = [...scrolledY.current.slice(-limit), number];
    calculateDirection(number, offsetHeight);
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const width = "80px";

  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;

  const stickyHeader = useRef(null);
  return (
    <div style={{ position: "relative" }}>
      {/* <MobileMenu /> */}
      {/* <div id="header-spacing" style={{ height: 0 * _.get(stickyHeader.current, "offsetHeight") }} /> */}
      <div id="header-spacing" style={{ height: 0 }} />
      <div
        id="sticky-header"
        /*
        className="bg-white"
        style={{
          position: "fixed",
          width: "100%",
          top: scrollOffset,
          left: 0,
          zIndex: 990,
        }}
        */
      >
        <header ref={stickyHeader}>
          {isLoading ? <Pace color="#27ae60" /> : null}

          {
            ///*
            <div className="container">
              <Col3Container className="p-0 m-0">
                <div className="d-flex justify-content-between bg-grey px-3 py-1">
                  <div className="py-2 text-left my-auto standart-header">
                    {translate("header_top_text")}
                  </div>
                  <div className="py-2 text-right  my-auto">
                    <TelegramButton
                      button={translate("subscribe_in_telegram")}
                      className="btn-sm"
                    />
                  </div>
                </div>
              </Col3Container>
            </div>
            //*/
          }
          <div className="container">
            <Col3Container>
              <div className="d-flex pt-3 pb-3">
                <div className="justify-content-start d-flex my-auto" style={{ width }}>
                  <div><SideMenu /></div>
                  <div><LocalesSelector /></div>
                </div>
                <div className="mx-auto my-auto d-flex justify-content-center">
                  {settings.logoFileId ? (
                    <div className="text-right my-auto mr-2 pt-0">
                      <Link to={"/"}>
                        <SmartImage
                          src={fileUrl(settings.logoFileId, "preview.jpg")}
                          style={{ width: "29px", height: "29px"}}
                          className="rounded rounded-circle -border -border-silver"
                        />
                      </Link>
                    </div>
                  ) : null}

                  <div className="text-left my-auto">
                    <LogoImage />
                  </div>
                </div>
                <div className="-text-right justify-content-end d-flex my-auto" style={{ width }}>
                  <UserLogin />
                  <div className="pl-3" />
                  <SearchModal />

                  {/*Top Navigation Bar Component*/}
                  {/* {props.rightButton || null} */}
                </div>
              </div>
            </Col3Container>
          </div>
        </header>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
  whatsapp_message: state.whatsapp.message,
  headerSpaceHeight: state.navi.headerSpaceHeight,
});

export default connect(mapStateToProps, { setHeaderSpaceHeight })(Header);
