import _ from "lodash";
import qs from "query-string";

export const applyUrlParams = (url, params, query) => {
  // todo replace keys in url by params
  _.each(params, (value, key) => {
    url = url.replace(`{${key}}`, value);
  });
  return url + "?" + qs.stringify(query);
};

export const autoDetect = (url, method) => {
  let route = {};

  if (method === "AUTO") {
    route = _.get(window.botcast_routes, url);
    if (!route) {
      throw console.log(`no auto route ${url}`);
    }
  }
  // /*
  route.path && (url = route.path);
  route.method && (method = route.method);

  return { url, method };
};
