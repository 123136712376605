import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { frontendApi } from "reducers/api";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "query-string";
import { Waypoint } from "react-waypoint";
import { useTranslate } from "react-redux-multilingual";
import { LoadingIcon, useWindowSize, keywordsSlice } from "components/standart";

import Col3Container from "components/standart/col3Container";

import CategoriesList from "./categories";
import CastData, { CastInfo, CastInfoSmall, MoreCasts } from "./cast";
import { SwiperBlock } from "components/standart/swiper-block";
import { TranslationsLinkBlock } from "../live";
import FavoritesCasts from "../account/casts";
import { InfoBlocks, LiveStreamShort } from "../infoblocks";
import TopCastsBlock from "./top-block";
import SpecialsCastsBlock from "../specials/swiper-block";
import SpecialsCastData from "../specials/cast";

const { api: apiFrontend } = frontendApi.actions;

const CastsIndex = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();

  const [categories, setCategories] = useState([]);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentCastId, setCurrentCastId] = useState(null);
  const [specialCastId, setSpecialCastId] = useState(null);
  const [currentCast, setCurrentCast] = useState({});
  const [specialCast, setSpecialCast] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [castsList, setCastsList] = useState([]);
  const [castsGroupsList, setCastsGroupsList] = useState({});
  const [castsLoading, setCastsLoading] = useState();
  const [hasMoreItems, setHasMoreItems] = useState();

  const { session } = useSelector((state) => state.user);

  // в случае изменения location заменим в истории значение на то, которое обнаружилось в адресной строке
  useEffect(() => {
    // history.replace([location.pathname, location.search].join(""));
    const { categoryId, category, castId, specialId } = qs.parse(location.search);
    if (!categoryId) {
      setCurrentCategoryId(null);
    } else {
      setCurrentCategoryId(categoryId);
    }
    if (!category) {
      setCurrentCategory(null);
    } else {
      setCurrentCategory(category);
    }

    setIsReady(true);
    // отработать логику чуть позже
    setCurrentCastId(castId);
    setSpecialCastId(specialId);
  }, [location.search]);

  useEffect(() => {
    props.getCastsCategories(
      {},
      {
        onSuccess(body) {
          setCategories(body);
        },
      }
    );
  }, []);

  useEffect(() => {
    const query = {};
    if (currentCastId) {
      query.castId = currentCastId;
      props.getCastInfo(
        { query },
        {
          onSuccess(body) {
            setCurrentCast(body);
          },
          onRequest() {
            setCurrentCast({});
          },
          onFailure() {
            setCurrentCast(null);
          },
        }
      );
    }
  }, [currentCastId]);

  useEffect(() => {
    const query = {};
    if (specialCastId) {
      query.specialId = specialCastId;
      props.getSpecialCastInfo(
        { query },
        {
          onSuccess(body) {
            setSpecialCast(body);
          },
          onRequest() {
            setSpecialCast({});
          },
          onFailure() {
            setSpecialCast(null);
          },
        }
      );
    }
  }, [specialCastId]);

  const loadCastsList = useCallback(
    (offset) => {
      const query = { lastOnly: true, offset };
      if (currentCategoryId) {
        query.categoryId = currentCategoryId;
      }
      if (currentCategory) {
        query.category = currentCategory;
      }

      props.getCastsList(
        { query },
        {
          onSuccess(body) {
            // setHasMoreItems(body.length > 0);
            // setCastsGroupsList(_.groupBy(body), "channel")
            // поместим выпуски в группы по категориям
            const castsGroups = {};
            _.each(body, (cast) => {
              // const dataSet = cast.channelId ? cast.categoryId : "general";
              const dataSet = cast.categoryId;
              if (!castsGroups[dataSet]) {
                castsGroups[dataSet] = [];
              }
              castsGroups[dataSet].push(cast);
            });
            setCastsGroupsList(castsGroups);
            // setCastsList(offset ? [...castsList, ...body] : [...body]);
            setCastsLoading(false);
          },
          onRequest() {
            setCastsLoading(true);
          },
          onFailure() {
            setCastsLoading(false);
          },
        }
      );
    },
    [currentCategoryId, currentCategory, castsList]
  );

  useEffect(() => {
    if (!currentCastId && isReady) {
      setCurrentCast({});
      setCastsList([]);
      loadCastsList(0);
    }
  }, [
    // currentCategoryId, currentCategory,
    currentCastId,
    isReady,
  ]);

  const selectCastId = (castId) => {
    navigate(castId ? `/?castId=${castId}` : "/?");
  };

  const size = useWindowSize();

  return (
    <section className={props.className}>
      <div className="container py-0">
        <Col3Container>
          <div>
            {/* 
            <CategoriesList
              currentCategoryId={_.get(currentCast, "categoryId") || currentCategoryId}
              currentCategory={currentCategory}
            />
            */}

            {currentCastId || specialCastId ? (
              <>
                {currentCastId ? (
                  <>
                    {currentCast ? (
                      <CastData cast={currentCast} />
                    ) : (
                      <div className="large-header text-center p-5">404</div>
                    )}
                  </>
                ) : (
                  <>
                    {specialCast ? (
                      <SpecialsCastData cast={specialCast} />
                    ) : (
                      <div className="large-header text-center p-5">404</div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="mb-4">
                {/* <TopCastsBlock /> */}
                <LiveStreamShort />
                {session.account ? <FavoritesCasts size={size} /> : null}
                <SpecialsCastsBlock />

                {_.size(castsGroupsList.general) ? (
                  <div>
                    {/* <div className="text-center large-header pb-3">{translate("general")}</div> */}

                    <SwiperBlock
                      id="general-casts"
                      slides={_.map(castsGroupsList.general, (cast, index) => {
                        return (
                          <div
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => selectCastId(cast._id)}
                          >
                            <CastInfo
                              cast={cast}
                              key={index}
                              // showFavorite={true}
                              // showPinned={true}
                              defaultImageId={cast.channel?.fileId}
                            >
                              {/* <div className="py-2">
                                <ShowCastDuration {...cast} />
                              </div> */}
                            </CastInfo>
                          </div>
                        );
                      })}
                    />
                  </div>
                ) : null}

                {_.map(categories, (category, index) => {
                  return _.size(castsGroupsList[category._id]) ? (
                    <div key={category._id}>
                      <div className="text-center -mr-auto large-header pt-2 pb-2">
                        {category.name}
                      </div>

                      <div className="text-center standart-header pb-4">
                        {keywordsSlice(castsGroupsList[category._id][0].keywords)}
                      </div>
                      <SwiperBlock
                        id={`${category.ident}-casts`}
                        slidesPerView={size.width > 750 ? 3 : 2}
                        slides={_.concat(
                          index > 0
                            ? []
                            : [
                                // <TranslationsLinkBlock />
                              ],
                          _.map(_.slice(castsGroupsList[category._id], 0, 10), (cast, index) => {
                            return (
                              <div
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectCastId(cast._id)}
                              >
                                <CastInfoSmall
                                  cast={cast}
                                  key={index}
                                  showPinned={!cast.channel}
                                  // showFavorite={true}

                                  defaultImageId={cast.channel?.fileId}
                                >
                                  {/* <div className="py-2">
                                  <ShowCastDuration {...cast} />
                                </div> */}
                                </CastInfoSmall>
                              </div>
                            );
                          })
                        )}
                      />
                      <div className="text-center">
                        <MoreCasts {...category} />
                      </div>
                    </div>
                  ) : null;
                })}
                {castsLoading ? (
                  <div className="text-center p-5">
                    <LoadingIcon />
                  </div>
                ) : null}

                <InfoBlocks />
              </div>
            )}
          </div>
        </Col3Container>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, {
  getCastsList: apiFrontend.get("/casts"),
  getCastsCategories: apiFrontend.get("/casts/categories"),
  getCastInfo: apiFrontend.get("/casts/info"),
  getSpecialCastInfo: apiFrontend.get("/specials/info"),
})(CastsIndex);
