import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Popover, PopoverBody } from "reactstrap";

export const contentId = "content";
export const friendId = "friend";
export const celebrityId = "celebrity";

export const ShareButton = (props) => {
  const { locales } = props;
  const [copied, setCopied] = useState(false);
  const tooltipTimeout = useRef();
  useEffect(() => {
    if (copied) {
      tooltipTimeout.current = setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const { channelId } = props;
  const translate = useTranslate();
  const [url, setUrl] = useState("");
  const [id, setId] = useState(null);
  useEffect(() => {
    // setUrl(`${document.location.origin}/share/${share}/${currentId}?lang=${locales.data.code}`);
    // setId(`daily_${currentId}`);
    setUrl(`${document.location.href}`);
    setId(`share_${channelId}`);
  }, [channelId]);

  const copyLink = (event) => {
    // metrikaTargetClick({ ...event });
    event.preventDefault();
  };

  return (
    <>
      <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
        <a
          href={url}
          id={id}
          className="-d-block -text-black"
          target="_blank"
          title={translate("share")}
          data-id="share-daily"
          onClick={copyLink}
        >
          {props.children}
        </a>
      </CopyToClipboard>

      {copied && (
        <Popover fade={true} placement="top" isOpen={copied} target={id}>
          <PopoverBody>{translate("url_copied")}</PopoverBody>
        </Popover>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  locales: state.locales,
});

export default connect(mapStateToProps, {})(ShareButton);
