import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { scrollTop } from "components/standart";
import Auth from "views/auth";

import { userApi } from "reducers/api";

import Settings from "./settings";
// import FavoritesCasts from "./casts";

import Col3Container from "components/standart/col3Container";

const { session } = userApi.actions;

const Account = (props) => {
  const location = useLocation();
  const { translate, sessionStore } = props;
  const { meta = {} } = props.env;

  useEffect(() => {
    if (!sessionStore.account) return;
    props.getUser();
  }, []);

  useEffect(() => {
    scrollTop('account/index.jsx');
  }, [location.pathname]);

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
  } = meta.account || {};

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <section className="bg-white m-0 p-0">
        <div className="container py-0">
          <Col3Container>
            <div className="text-center pb-4">
              {sessionStore.account ? (
                <div>
                  {/* <FavoritesCasts /> */}
                  <div className="large-header text-truncate pt-2 pb-2">
                    {translate("account")}
                    {/* {account.profile?.name || login.value} */}
                  </div>
                  <Settings />
                </div>
              ) : (
                <div>
                  <div className="large-header">{translate("auth_title")}</div>
                  <Auth />
                </div>
              )}
            </div>
          </Col3Container>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.user.session,
  env: state.frontend.env,
});

export default withTranslate(connect(mapStateToProps, { ...session.actions })(Account));
