import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-redux-multilingual";
import { useSelector } from "react-redux";

import NumberFormat from "react-number-format";

// формат номера телефона
export const phoneFormat = "# (###) ###-####";

export const emojies = ["❤️"];

export const showMoney = (value, symbol = "") => (
  <NumberFormat
    displayType="text"
    suffix={` ${symbol}`}
    thousandSeparator={" "}
    decimalSeparator={"."}
    decimalScale={2}
    allowNegative={false}
    value={value}
  />
);

export const showDigits = (value) => (
  <NumberFormat
    displayType="text"
    thousandSeparator={" "}
    decimalScale={0}
    allowNegative={false}
    value={value}
    className="text-nowrap"
  />
);

export const scrollTop = (...args) => {
  // console.log("do scroll top", args);
  window.scrollTo(0, 0);
};

// Hook
export function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export const PayIcons = (props) => (
  <div className="text-muted" style={{ fontSize: "2.3em" }}>
    {/* <i className="fab fa-cc-visa mr-1" /> */}
    {/* <i className="fab fa-cc-mastercard mr-1" /> */}
    {/* <i className="fab fa-cc-apple-pay mr-1" /> */}
    <i className="fab fa-apple-pay mr-3" />
    <i className="fab fa-google-pay" />
  </div>
);

export const safeScrollIntoView = (element, extraOffset = 0) => {
  setTimeout(() => {
    const { offsetTop } = element;
    const header = document.getElementById("sticky-header");
    const { offsetHeight } = header;
    const { top } = header.style;
    const headerTop = +top.substr(0, top.length - 2);
    const resultTop = offsetTop + extraOffset - (offsetHeight + headerTop);
    window.scrollTo({
      top: resultTop,
    });
  }, 300);
};

export const modalOpenFix = (isOpen) => {
  // isOpen && (document.body.style.overflow = "hidden");
  // !isOpen && (document.body.style.overflow = "unset");
};

export const counterValue = (value) => {
  return value > 9 ? "9+" : value;
};

export const LoadingIcon = (props) => {
  return <i className={`fa fa-spinner-third fa-spin ${props.className}`} />;
};

export const backgroundCover = (url) => {
  return {
    backgroundImage: `url(${url})`,
    // backgroundImage: `image(${url}, ${url}?dropcache=${Date.now()})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
};

export const SmartImage = (props) => {
  const [id, setId] = useState(`smart_image_${Math.random().toString().substring(3, 30)}`);
  useEffect(() => {
    const thisImage = document.getElementById(id);
    const onError = () => {
      thisImage.removeEventListener("error", onError);
      thisImage.src = `${props.src}?dropcache=${Date.now()}`;
    };
    thisImage.addEventListener("error", onError);
  }, []);
  return <img id={id} {...props} />;
};

export function fixLastChar(input) {
  input = _.trim(input);
  const lastChar = _.last(input);
  // если в конце нет никаких завершающих символов
  if (lastChar && !["!", "?", "."].includes(lastChar)) {
    return `${input}.`;
  }
  return input;
}

export function keywordsSlice(keywords) {
  return _.slice(keywords, 0, 3).map(fixLastChar).join(" ");
}

export const castTitle = (cast) => {
  return cast.channel?.name || cast.category?.castTitle || cast.category?.name;
};

export const castFirstSound = (cast, messages) => {
  const firstSound = cast.channelId
    ? messages[0]
    : cast.category.soundFileId
    ? {
        // если у выпуска по категории есть озвучка, то включим ее, иначе первое сообщение
        title: castTitle(cast),
        fileId: cast.category.soundFileId,
        content: cast.category.soundText,
        imageId: cast.fileId,
        playNext: true,
      }
    : messages[0];

  return firstSound;
};

export const LiveIndicator = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  return (
    <a
      href={`${settings.telegramChannel}?livestream`}
      target="_blank"
      className="text-decoration-none text-white"
    >
      <small
        className="py-1 px-3 rounded"
        style={{ backgroundColor: "rgb(220, 53, 69)", color: "white" }}
      >
        {/* <i className="fal fa-signal-stream mr-1" /> */}
        {translate("translation_time")}
      </small>
    </a>
  );
};
