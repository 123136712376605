/** автономная форма авторизации
 * может быть вставлена в другие связанные компоненты
 */
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import isemail from "isemail";
import { Alert } from "reactstrap";

const LoginForm = (props) => {
  const { locales, type } = props;
  const translate = useTranslate();
  const [auth, setAuth] = useState({ login: "", code: "" });
  const [codeRequested, setCodeRequested] = useState(null);
  const [error, setError] = useState(false);
  const [offerRequired, setOfferRequired] = useState(false);
  const [offerAccepted, setOfferAccepted] = useState(true);
  const [validPhone, setValidPhone] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const changeCode = ({ target }) => {
    const { value } = target;
    setAuth({ ...auth, code: value });
  };
  /*
  const changePhoneNumber = ({ target }) => {
    const { value = "" } = target;
    const login = value
      ? value[0] === "7"
        ? value
        : "7" + (value[0] === "8" ? value.substr(1) : value)
      : "";
    setAuth({ ...auth, login });
    if (value.trim().length === phoneFormat.length) {
      setValidPhone(true);
    } else {
      setValidPhone(null);
    }
  };

  */
  const focusTimeout = useRef(null);

  const changeEmail = ({ target }) => {
    const { value = "" } = target;
    const login = value;
    setAuth({ ...auth, login });
    if (isemail.validate(login)) {
      setValidEmail(true);
    } else {
      setValidEmail(null);
    }
  };

  const emailRef = useRef(null);
  const codeNumber = useRef(null);

  const timeoutStore = useRef(false);
  useEffect(() => {
    return () => {
      setOfferRequired(false);
      setCodeRequested(false);
      clearTimeout(timeoutStore.current);
      clearTimeout(focusTimeout.current);
    };
  }, []);

  const setTimer = (time) => {
    setSeconds(time);
    timeoutStore.current = setTimeout(() => {
      time > 0 && setTimer(time - 1);
    }, 1000);
  };

  const onFailure = (result) => {
    setError(result.message);
  };
  const onRequest = () => {
    setError(false);
  };

  const sendCode = (login) => {
    const onSuccess = (body) => {
      setError(false);
      setCodeRequested(login);
      setOfferRequired(body.policyConfirmationRequired);
      focusTimeout.current = setTimeout(() => {
        codeNumber.current.focus();
      }, 500);
      setTimer(60);
    };
    props.requestHandler(
      { body: { login }, query: { type, localeId: locales.data?._id } },
      { onSuccess, onRequest, onFailure },
    );
  };

  const submitHandler = () => {
    if (!codeRequested && validEmail) {
      sendCode(auth.login);
    } else if (codeRequested) {
      const body = { ...auth };
      if (offerRequired) {
        Object.assign(body, {
          offerAccepted,
          policyConfirmed: offerAccepted,
          privacyConfirmed: offerAccepted,
        });
      }
      props.confirmHandler({ body, query: { type } }, { onRequest, onFailure });
    }
  };

  return (
    <div className="text-center">
      {/* 
      <div className="form-group">
        <NumberFormat
          type="tel"
          name="phone"
          ref={phoneNumber}
          format={phoneFormat}
          value={auth.login}
          placeholder={translate("phone_number")}
          className={`form-control`}
          readOnly={!!codeRequested}
          onChange={changePhoneNumber}
          onBlurCapture={changePhoneNumber}
          autoComplete="tel"
        />
      </div>
       */}
      

      {!codeRequested ? (
        <div className="form-group mb-4">
        <label className="standart-header mb-3">{translate("auth_is_simple")}</label>

        <div className="form-group my-3">
          <input
            type="email"
            name="email"
            ref={emailRef}
            value={auth.login}
            placeholder={translate("email")}
            className={`form-control text-center`}
            readOnly={!!codeRequested}
            onChange={changeEmail}
            onBlurCapture={changeEmail}
          />
        </div>
      </div>
      ) : (
        <>
          <Alert className="alert-success">
            {translate("auth_codeSent")}
            <br />
            {seconds ? (
              translate("auth_reSend", { seconds })
            ) : (
              <span
                className="url"
                style={{ cursor: "pointer" }}
                onClick={() => sendCode(codeRequested)}
              >
                {translate("auth_reRequesCode")}
              </span>
            )}
          </Alert>

          <div className="form-group my-3">
            <label className="standart-header mb-3">{translate("auth_code")}</label>
            <input
              ref={codeNumber}
              type="tel"
              name="code"
              placeholder={translate("code")}
              value={auth.code}
              onChange={changeCode}
              className="form-control text-center"
              autoComplete="one-time-code"
            />
          </div>
        </>
      )}

      {error ? (
        <Alert className="alert-danger" isOpen={true} toggle={() => setError(false)}>
          {translate(error) || error}
        </Alert>
      ) : (
        <></>
      )}

      <div className="my-5">
        <button
          type="button"
          className="btn btn-solid standart-header py-3"
          disabled={
            !validEmail ||
            (codeRequested && !auth.code) ||
            ((offerRequired || !codeRequested) && !offerAccepted)
          }
          onClick={submitHandler}
        >
          {translate(codeRequested ? "auth_validateCode" : "auth_requestCode")}
        </button>
      </div>

      {offerRequired && (
        <div className="form-group mt-4">
          <div className="pretty p-icon p-fill mr-2">
            <input
              id="offer_required"
              value={true}
              type="checkbox"
              onChange={() => setOfferAccepted(!offerAccepted)}
              checked={offerAccepted}
            />
            <div className="state">
              <i className="icon fa fa-check" />
              <label />
            </div>
          </div>

          <label className="d-inline" htmlFor="offer_required">
            {translate("auth_offer_required")}
          </label>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  locales: state.locales,
});

export default connect(mapStateToProps, {})(LoginForm);
