import _ from "lodash";

import React, { useState, useEffect, useRef, Fragment, useCallback } from "react";
import ReactPlayer from "react-player";
import { connect, useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import { FavoriteButton } from "components/standart/channelBlock";
import { iconFontSize } from "constants";

import { fileUrl } from "reducers/api";
import {
  setPlayerMessages,
  setPlayerVisible,
  setPlayerMessage,
  setPlayerCurrentId,
  setPlayerFavoritePlaying,
  // setPlayerAdvert,
  setPlayerChannels,
  setPlayerPlay,
  setPlayerData,
  setPlayerSound,
  setPlayerCastId,
  // setPlayerLastChannel,
} from "actions";
import { PLAYER_TYPE_ADVERT, PLAYER_TYPE_MESSAGE, PLAYER_TYPE_SOUND } from "constants";

import PlayerMessage from "./message";
import { useSockets } from "./refs/sockets";
import { useNoSleep } from "./refs/nosleep";
import { useCheckVisible } from "./refs/visibleChecker";
import { usePlayer, PlayerContainer } from "./refs/playerjs";
import { useLocation } from "react-router-dom";

const RadiogramPlayer = (props) => {
  const playerId = "js-player";

  const { player } = props;
  const dispatch = useDispatch();
  const { playerRef } = usePlayer();
  const { noSleepRef } = useNoSleep();
  const { visibleChecker } = useCheckVisible();

  const [playerJS, setPlayerJS] = useState(false);

  const [bgSound, setBgSound] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(-1);
  const nextIndex = useRef(-1);

  const isMessage = (data = {}) => _.get(data, "channelId");
  const isAdvert = (data = {}) => _.get(data, "advertCompanyId");

  useEffect(() => {
    if (!player.sound) {
      dispatch(setPlayerVisible(false));
      return;
    }

    let data;

    if (isMessage(player.sound)) {
      const message = player.sound;
      const channels = player.channels;
      const { alias } = channels[message.channelId];
      const { ident } = message;
      const url = ident ? `https://t.me/${alias}/${ident}` : null;
      data = {
        id: message._id,
        // date: message.createdAt,
        image: fileUrl(channels[message.channelId]?.fileId, "preview.jpg"),
        title: channels[message.channelId]?.name,
        text: message.text,
        mp3: message.mp3,
        url,
        type: PLAYER_TYPE_MESSAGE,
      };
      dispatch(setPlayerMessage(message));
    } else if (isAdvert(player.sound)) {
      const advert = player.sound;
      data = {
        id: advert.advertCompanyId,
        image: advert.image,
        title: advert.title,
        text: advert.content,
        url: advert.url,
        mp3: advert.mp3,
        type: PLAYER_TYPE_ADVERT,
      };
    } else {
      const sound = player.sound;
      data = {
        id: sound.fileId,
        image: fileUrl(sound.imageId, "image.jpg"),
        title: sound.title,
        text: sound.content,
        mp3: fileUrl(sound.fileId, "file.mp3"),
        type: PLAYER_TYPE_SOUND,
        playNext: sound.playNext,
      };
    }
    dispatch(setPlayerData(data));
  }, [player.sound]);

  useEffect(() => {
    // player.sound && definePlayingSound();
  }, [player.data]);

  // на переключении списка сообщений в плеере отправим их в playerJs
  useEffect(() => {
    playerJS && playerRef.current.playlist(player.messages, player.channels);
  }, [playerJS, player.messages]);

  // на переключении сообщения в плеере отправим команду отыграть его в playerJs
  useEffect(() => {
    playerJS && player.play && playerRef.current.play(player.message, player.channels);
  }, [playerJS, player.message, player.play]);

  // на переключении сообщения в плеере отправим команду отыграть его в playerJs
  useEffect(() => {
    if (playerJS && player.currentId) {
      playerRef.current.setOnlyCurrentId(player.currentId);
      // console.log ('playerJS && player.currentId', player.currentId)
      dispatch(setPlayerCurrentId(null));
    }
  }, [playerJS, player.currentId]);

  // при активации звукового сообщения в плеере запустим его проигрывание
  useEffect(() => {
    if (playerJS && player.data?.type === PLAYER_TYPE_SOUND) {
      playerRef.current.playSoundMessage(player.data);
    }
  }, [playerJS, player.data]);

  useEffect(() => {
    if (playerJS) {
      playerRef.current.command(player.play ? "play" : "pause");
    }
  }, [playerJS, player.play]);

  // на изменении списка сообщений и самого сообщения переопределим
  useEffect(() => {
    defineCurrentIndex();
  }, [player.message, player.messages]);

  // на переключении значения текущего индекса готовим значение следующего индекса
  useEffect(() => {
    if (currentIndex >= 0) {
      dispatch(setPlayerSound(player.messages[currentIndex]));
      nextIndex.current = currentIndex + 1;
    } else {
      nextIndex.current = -1;
    }
  }, [currentIndex]);

  // определим текущую позицию сообщения в списке
  const defineCurrentIndex = () => {
    const messageIndex = _.findIndex(player.messages, ({ _id }) => _id === player.message?._id);
    setCurrentIndex(messageIndex);
  };

  // // определим текущий фоновый звук
  // const initBgSound = () => {
  //   setBgSound(playerRef.current.getBgSound());
  // };

  // определим текущий фоновый звук
  const bgSoundStarted = () => {
    playerRef.current.setBgSoundLoaded();
  };

  // определим текущий фоновый звук
  const nextBgSound = () => {
    setBgSound(playerRef.current.nextBgSound());
  };

  // на создании плеера загрузим приветствия для рекламы
  useEffect(() => {
    playerRef.current.shuffleBgSounds(); // перемешаем имеющиеся звуки
    nextBgSound(); // определим следующий звук

    playerRef.current.init(playerId);
    setPlayerJS(true);
    noSleepRef.current.enable();

    return () => {
      playerRef.current.destroy();
      setPlayerJS(false);
      noSleepRef.current.disable();
    };
  }, []);

  const startNextIndex = () => {
    player.messages[nextIndex.current]
      ? dispatch(setPlayerMessage(player.messages[nextIndex.current]))
      : playerRef.current.startFinalMessage();
  };

  const playPauseHandler = () => {
    console.log("started playPauseHandler");
    dispatch(setPlayerPlay(!player.play));
  };

  // обработка перехода по ссылке
  const goToUrl = (data) => {
    if (data.url) {
      window.open(data.url, "_blank"); //.focus();
    }
  };

  return (
    <div>
      <div
        style={{
          height: "0px",
          overflow: "hidden",
          visibility: "hidden",
        }}
      >
        <PlayerContainer id={playerId} />

        {bgSound ? (
          <ReactPlayer
            url={fileUrl(bgSound.volumeFileId, "file.mp3")}
            playsInline={true}
            controls={false}
            onStart={bgSoundStarted}
            onEnded={nextBgSound}
            volume={1}
            // volume={bgSound.volume ? bgSound.volume / 100 : 0.5}
            // loop={true}
            playing={player.play}
          />
        ) : null}
      </div>

      {player.data ? (
        <Fragment>
          <PlayerMessage data={player.data} onClick={() => goToUrl(player.data)}>
            <div>
              <PlayForwardButtons
                {...{ playPauseHandler, startNextIndex }}
                disabled={
                  // false &&
                  player.data.type !== PLAYER_TYPE_MESSAGE
                  // || nextIndex.current >= _.size(player.messages)
                }
              />
            </div>
          </PlayerMessage>
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
};

const PlayForwardButtons = (props) => {
  const player = useSelector((state) => state.player);

  return (
    <Fragment>
      <div className="text-nowrap mt-2 ml-2">
        {/* тут кнопка для избранного */}
        {/* 
        {player.sound?.channelId ? (
          <FavoriteButton
            disabled={!player.sound?.channelId}
            className="btn-sm"
            iconClassName="h4 m-0 p-0"
            isButton={true}
            channel={{ _id: player.sound?.channelId }}
            size="small"
            style={{ marginTop: "10px" }}
            // fontSize={iconFontSize}
          />
        ) : null}
         */}

        {/* <ControlButton className="btn-sm mr-2" {...{ message: { ...currentMessage } }} /> */}
        <button className="btn btn-sm" disabled={player.disabled} onClick={props.playPauseHandler}>
          <i className={`fa ${player.play ? "fa-pause" : "fa-play"} h4 m-0 p-0`} />
        </button>

        <button
          className="btn btn-sm"
          disabled={props.disabled || player.disabled}
          onClick={props.startNextIndex}
        >
          <i className="fa fa-forward h4 m-0 p-0" />
        </button>
      </div>
    </Fragment>
  );
};

export const IndicatorButton = (props) => {
  const { message } = props;
  const player = useSelector((state) => state.player);
  const playlist = useSelector((state) => state.playlist);

  return (
    <div className="text-right">
      <small className="mx-auto text-secondary">
        {player.message?._id === message._id ? (
          <i
            className={`fas fa-circle ${player.play && !props.disabled ? "blinking" : ""}`}
            style={{ color: "#dc3545" }}
          />
        ) : (
          <Fragment>
            {_.get(playlist.listenedMessagesId, message?._id) ? (
              <i className="fal fa-check" />
            ) : null}
          </Fragment>
        )}
      </small>
    </div>
  );
};

export const CommonPlayerButton = (props) => {
  const { message } = props;
  const player = useSelector((state) => state.player);
  const { channels, messages, currentCastId } = useSelector((state) => state.playlist);
  const location = useLocation();

  const dispatch = useDispatch();
  const togglePlay = () => {
    dispatch(setPlayerVisible(true));
    if (player.message?._id === message._id) {
      // dispatch(togglePlayerPlay());
      dispatch(setPlayerPlay(!player.play));
    } else {
      const { favorites } = qs.parse(location.search);
      dispatch(setPlayerFavoritePlaying(!!favorites));

      dispatch(setPlayerChannels(_.values(channels)));
      dispatch(setPlayerSound(message));
      dispatch(setPlayerMessages(messages));
      dispatch(setPlayerCastId(currentCastId));
      setTimeout(() => {
        dispatch(setPlayerPlay(true));
      }, 50);
    }
  };
  const [disabled, setDisabled] = useState(null);
  useEffect(() => {
    setDisabled(!message?._id || player.disabled);
  }, [message, player]);

  const onClickHandler = (...args) => {
    if (disabled) return false;
    return Reflect.apply(props.onClick || togglePlay, null, args);
  };

  return (
    <div className={`-btn -btn-sm ${props.className}`} onClick={onClickHandler}>
      {props.children}
    </div>
  );
};

const PlayerMenu = (props) => {
  const { player } = props;
  const { socketsRef } = useSockets();

  const height = 7;

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(_.round(100 * player.progress));
  }, [player.progress]);

  return player.visible ? (
    <div className="bg-silver ">
      {/* 
      <div className="d-block">
        <ChannelInfo channel={player.showChannel} />
      </div>
      */}
      <div className="d-block" style={{ lineHeight: 0 }}>
        <div
          className="d-inline-block"
          style={{ backgroundColor: "#000000", width: `${width}%`, height }}
        ></div>
        <div
          className="d-inline-block"
          style={{
            backgroundColor: "#000000",
            opacity: "10%",
            width: `${100 - width}%`,
            height,
          }}
        ></div>
      </div>
      <div className="px-3 pt-2 pb-3">
        <RadiogramPlayer {...props} />
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  player: state.player,
  env: state.frontend.env,
});

export default connect(mapStateToProps, null)(PlayerMenu);
