/**
 * генератор запросов к СloClo API
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 *
 */
import _ from "lodash";
import { combineReducers } from "redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import frontendReducers from "./reducers";
import { sessionToken, accessToken } from "../token";
// import auth from "../auth/auth";

export default frontendReducers;

// типовой редюсер для API
export function reducer(state = {}, action) {
  console.log("do regular reducer", action);
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const frontendApiReducer = combineReducers({
  api: reducer,
  ...frontendReducers.reducers,
});

export const frontendApiMiddleware = (store) => (next) => (action = { type: "" }) => {
  const result = next(action);
  const { payload } = action;

  if (_.get(payload, "message") === "Failed to fetch") {
    console.log("enable lock screen");
  }

  // если по какой-то причине вернулся 401 или 303, то полностью закроем сессию и удалим токены
  if ([401, 303].includes(_.get(payload, "status"))) {
    // console.log(new Date(), "this is logout???");
    localStorage.removeItem(sessionToken);
    localStorage.removeItem(accessToken);
    localStorage.removeItem("state");
    document.location.assign("/");
  }

  /*
  // если по какой-то причине вернулся 303, то полностью обновим страницу
  // это означает, что умерла кука сессии, или сломался отпечаток клиента
  if (303 === _.get(payload, "status")) {
    store.dispatch(
      auth.actions.logout(
        {},
        {
          onRequest() {
            document.location.assign("/");
          },
        },
      ),
    );
    // console.log("wtf with 303?", result, payload);
    // document.location.reload();
    // document.location.href = _.get(payload, "response.message");
  }
  */

  return result;
};

export const middlewares = [frontendApiMiddleware, ...frontendReducers.middlewares];
