import React from "react";
import ym, { YMInitializer } from "react-yandex-metrika";
import { history } from "store";

const prerender = history.location.search.search(/_escaped_fragment_|prerender/) >= 0;

const { NODE_ENV } = process.env;
const { YANDEX_METRIKA_NUMBER } = process.env;

const isProduction = NODE_ENV === "production";

const YMEnabled = +YANDEX_METRIKA_NUMBER && isProduction && !prerender;

export function metrikaTargetClick(event) {
  // console.log({ event });
  const { currentTarget: target } = event;
  const { id: target_id } = target.dataset || {};
  // /*
  if (target_id && YMEnabled) {
    ym("reachGoal", target_id);
  } else {
    console.log("yandex reach goal", { target_id });
  }
  //*/
}

export function YMInit() {
  return YMEnabled ? (
    <YMInitializer
      accounts={[+YANDEX_METRIKA_NUMBER]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        ecommerce: "dataLayer",
      }}
      // version="2"
    />
  ) : null;
}
