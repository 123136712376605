import _ from "lodash";

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IntlActions, useTranslate } from "react-redux-multilingual";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { scrollTop } from "components/standart";
import { clearWhatsAppMessage, setLocaleData, resetPlayer } from "actions";
import { frontendApi, userApi, authApi } from "reducers/api";
import { accessToken } from "reducers/api/token";
import BottomMenu from "components/common/bottom_menu";

import qs from "query-string";

import "components/common/index.scss";
import { YMInit } from "services";

const { env } = frontendApi.actions;
const { auth } = authApi.actions;
const { session, env: systemEnv } = userApi.actions;

// FBPixel.pageView();

const App = (props) => {
  const translate = useTranslate();
  const { locales, userEnv } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const authStore = useSelector((state) => state.auth.auth);
  const player = useSelector((state) => state.player);
  const [renderIdent, setRenderIdent] = useState(Math.random());
  const [playerReady, setPlayerReady] = useState(null);

  useEffect(() => {
    if (locales.data?._id) {
      props.landings({ query: { localeId: locales.data._id } });
      props.meta({ query: { localeId: locales.data._id } });
    }
  }, [locales.data]);

  useEffect(() => {
    const { lang } = qs.parse(location.search);
    let existsLocale;
    if (locales.data?._id) {
      [existsLocale] = _.filter(userEnv.locales, (locale) => locale._id === locales.data?._id);
    }
    if (_.size(userEnv.locales) && (!existsLocale || lang || !locales.data?._id)) {
      const userLang = lang || navigator.language || navigator.userLanguage;
      const [code] = _.split(userLang, "-");
      const [bestLocale] = _.filter(
        userEnv.locales,
        (locale) => _.toLower(locale.code) === _.toLower(code)
      );
      const [defaultLocale] = _.filter(userEnv.locales, (locale) => locale.isDefault);
      const [firstLocale] = userEnv.locales;
      const locale = bestLocale || defaultLocale || firstLocale;

      if (locale) {
        props.setLocaleData(locale);
        props.setLocale(_.toLower(locale.code));
      }
    }
  }, [userEnv]);

  useEffect(() => {
    if (!authStore.session) {
      dispatch(auth.actions.session({ query: { originHref: document.location.href } }));
    } else {
      if (localStorage.getItem(accessToken)) {
        props.getUser();
      }

      props.settings();
      props.soundsSettings();
      props.soundsBackgrounds();
      props.playlistDefault();
      props.resetPlayer(renderIdent);
      props.systemEnv();
      props.clearWhatsAppMessage();
    }

    return () => {
      props.resetPlayer();
    };
  }, [authStore.session]);

  useEffect(() => {
    scrollTop();

    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 0);

    return () => {
      props.resetPlayer();
    };
  }, []);

  useEffect(() => {
    setPlayerReady(player.ident === renderIdent);
  }, [player.ident]);

  const sitename = () => {
    return _.get(props.env, "meta.sitename") || translate("meta_sitename");
  };

  return playerReady &&
    authStore.session &&
    props.env?.settings &&
    props.env?.soundsSettings &&
    props.env?.soundsBackgrounds &&
    props.env?.landings ? (
    <>
      <Helmet>
        <title>{sitename()}</title>
        <meta property="og:sitename" content={sitename()} />
        <meta property="og:site_name" content={sitename()} />
      </Helmet>

      {YMInit()}
      {/* <Header /> */}
      {props.children}
      {/* <Footer /> */}

      <div style={{ position: "relative" }}>
        <BottomMenu />
      </div>

      {/* 
      <CookieConsent
        background={"#000000"}
        bottomPosition={true}
        buttonText={translate("cookie_button")}
        buttonClassName="btn btn-solid white-btn"
        // buttonBackground={"#fff"}
        // buttonColor={"#000000"}
        // buttonFontSize={16}
        color={"#fff"}
        consentFunction={checkStatus}
        padding={20}
      >
        {translate("cookie_notice")}
      </CookieConsent>
       */}
    </>
  ) : null;
};

const mapStateToProps = (state) => ({
  userEnv: state.user.env,
  env: state.frontend.env,
  locales: state.locales,
});

export default connect(mapStateToProps, {
  ...env.actions,
  ...systemEnv.actions,
  ...session.actions,
  authApi,
  setLocaleData,
  resetPlayer,
  setLocale: IntlActions.setLocale,
  clearWhatsAppMessage,
})(App);
