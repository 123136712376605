import apiClass from "./init";
import { sessionToken, accessToken } from "../token";
import session from "../user/session";

const auth = new apiClass({ session: localStorage.getItem(sessionToken) });

auth.actions = {
  login: auth.post("/login"),
  requestCode: auth.post("/request"),
  validateCode: auth.post("/confirm"),
  session: auth.post("/session", {
    success(state, body) {
      localStorage.setItem(sessionToken, body.session);
      return { ...state, session: body.session };
    },
  }),
  logout: auth.post("/logout", {
    request(state) {
      localStorage.removeItem(sessionToken);
      localStorage.removeItem(accessToken);
      return { ...state, session: null };
    },
  }),
};

export const middleware = (store) => (next) => (action = { type: "" }) => {
  const result = next(action);
  const requestValidate = auth.url_states["/request"].ON_SUCCESS;
  const successValidate = auth.url_states["/confirm"].ON_SUCCESS;

  switch (action.type) {
    case successValidate:
    case requestValidate:
      // собственный middleware при успешном получении токенов
      const { type = "", accessToken: token = "" } = { ...action.payload };
      if (token) {
        // записать его в локальное хранилище
        localStorage.setItem(accessToken, `${type} ${token}`);

        // success  процедура при успешном получении информации о пользователе
        const onSuccess = ({ body }) => {};

        // получить информацию о пользователе
        store.dispatch(session.actions.getUser({}, { onSuccess }));
      }
      break;
    default:
      break;
  }
  return result;
};

export default auth;
