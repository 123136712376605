import _ from "lodash";
import React from "react";
import { SmartImage } from "components/standart";
import { fileUrl } from "reducers/api";

export const CastChannelsLogos = (cast) => {
  const { channels = [], limit = 10 } = cast;
  return channels.length ? (
    <div className="d-flex align-content-center justify-content-center partner-logos my-2">
      {_.map(channels, (channel, index) => {
        // const zIndex = channels.length + 1 - index;
        const zIndex = index + 1;
        return index < limit ? (
          <div
            className={`partner nano`}
            key={index}
            style={index ? { marginLeft: "-5px", zIndex } : { zIndex }}
          >
            <SmartImage
              src={fileUrl(channel.fileId, "preview.jpg")}
              className="rounded-circle border border-1"
            />
          </div>
        ) : null;
        // return <PartnerLogo partner={channel} size="sm" />;
      })}
      {false && channels.length > limit ? (
        <div
          className={`partner nano position-relative`}
          style={{ marginLeft: "-5px", zIndex: channels.length + 1 }}
        >
          <img
            src={"/grey.png"}
            className="rounded-circle"
            // style={{ border: "2px solid white" }}
          />
          <div
            className="position-absolute w-100 h-100 align-items-center d-flex"
            style={{ top: 0, left: 0 }}
          >
            <div className="w-100 justify-content-center text-secondary -font-weight-bold -standart-header">
              {/* <i className="far fa-ellipsis-h" /> */}
              <small>{`${channels.length}`}</small>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};
