import React from "react";
import { useTranslate } from "react-redux-multilingual";
import { Link } from "react-router-dom";

function LogoImage(props) {
  const translate = useTranslate();
  return (
    <>
      <Link to={`/`} className="d-block" style={{ textDecoration: "none" }}>
        {/* <img src={`/logo.png`} alt="" className="img-fluid" style={{ height: "40px" }} /> */}
        <div className="mb-1" style={{ color: "black" }}>
          <div
            style={{ fontSize: "1.4em", fontFamily: "Arial Black", fontWeight: "bold" }}
            className="text-lowercase"
          >
            {translate("sitename")}
          </div>
          {/* 
          <div
            style={{ fontSize: "0.69em", fontFamily: "Arial", marginTop: "-6px" }}
            className="text-lowercase"
          >
            {translate("sitename_helper")}
          </div>
           */}
        </div>
      </Link>
      <div className="clearfix" />
    </>
  );
}

export default LogoImage;
