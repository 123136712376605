import React from "react";
import path from "path";
import { connect, useSelector } from "react-redux";
import nl2br from "react-nl2br";
import { useTranslate } from "react-redux-multilingual";
import { iconFontSize } from "constants/index";
const style = { fontSize: iconFontSize };

const TelegramButton = (props) => {
  const { postId = "" } = props;
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const translate = useTranslate();
  return (
    <a
      className={`btn btn-rounded text-nowrap active -standart-header text-white ${props.className}`}
      style={{ backgroundColor: "#0088cc", borderColor: "#0088cc", borderWidth: "2px" }}
      target="_blank"
      href={[settings.telegramChannel, postId].filter(Boolean).join("/")}
    >
      <i className="fab fa-telegram-plane mr-2 text-white" />

      {props.button || nl2br(translate("listen_in_telegram"))}
    </a>
  );
};

export const RoundTelegramButton = (props) => {
  const { postId = "" } = props;
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const translate = useTranslate();
  return (
    <div className="d-flex flex-column">
      <a target="_blank" href={[settings.telegramChannel, postId].filter(Boolean).join("/")}>
        <div className="position-relative d-flex" style={{ cursor: "pointer" }}>
          <div className=" w-100 h-100">
            <img
              src={"/blue.png"}
              className="rounded-circle"
              // style={{ border: "2px solid black" }}
            />
          </div>
          <div
            className="position-absolute d-flex align-items-center justify-content-center h-100 w-100"
            style={{ ...style }}
          >
            <i className="fab fa-telegram-plane text-white" />
          </div>
        </div>
      </a>
      <div className="text-secondary mt-1">
        <small>{nl2br(props.button) || nl2br(translate("listen_in_telegram"))}</small>
      </div>
    </div>
  );
};

export const RoundYoutubeButton = (props) => {
  const { postId = "" } = props;
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const translate = useTranslate();
  return (
    <div className="d-flex flex-column">
      <div
      // target="_blank"
      //href={[settings.telegramChannel, postId].filter(Boolean).join("/")}
      >
        <div className="position-relative d-flex" style={{ cursor: "pointer" }}>
          <div className=" w-100 h-100">
            <img
              src={"/dark-grey.png"}
              className="rounded-circle"
              // style={{ border: "2px solid black" }}
            />
          </div>
          <div
            className="position-absolute d-flex align-items-center justify-content-center h-100 w-100"
            style={{ ...style }}
          >
            <i className="fab fa-youtube text-secondary" />
          </div>
        </div>
      </div>
      <div className="text-secondary mt-1">
        <small>{props.button || nl2br(translate("listen_in_youtube"))}</small>
      </div>
    </div>
  );
};

export const RoundVkButton = (props) => {
  const { postId = "" } = props;
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const translate = useTranslate();
  return (
    <div className="d-flex flex-column">
      <div
      // target="_blank"
      //href={[settings.telegramChannel, postId].filter(Boolean).join("/")}
      >
        <div className="position-relative d-flex" style={{ cursor: "pointer" }}>
          <div className=" w-100 h-100">
            <img
              src={"/dark-grey.png"}
              className="rounded-circle"
              // style={{ border: "2px solid black" }}
            />
          </div>
          <div
            className="position-absolute d-flex align-items-center justify-content-center h-100 w-100"
            style={{ ...style }}
          >
            <i className="fab fa-vk text-secondary" />
          </div>
        </div>
      </div>
      <div className="text-secondary mt-1">
        <small>{props.button || nl2br(translate("listen_in_apple"))}</small>
      </div>
    </div>
  );
};

export const RoundYandexButton = (props) => {
  const { postId = "" } = props;
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const translate = useTranslate();
  return (
    <div className="d-flex flex-column">
      <div
      // target="_blank"
      //href={[settings.telegramChannel, postId].filter(Boolean).join("/")}
      >
        <div className="position-relative d-flex" style={{ cursor: "pointer" }}>
          <div className=" w-100 h-100">
            <img
              src={"/dark-grey.png"}
              className="rounded-circle"
              // style={{ border: "2px solid black" }}
            />
          </div>
          <div
            className="position-absolute d-flex align-items-center justify-content-center h-100 w-100"
            style={{ ...style }}
          >
            <i className="fab fa-yandex text-secondary" />
          </div>
        </div>
      </div>
      <div className="text-secondary mt-1">
        <small>{props.button || nl2br(translate("listen_in_yandex"))}</small>
      </div>
    </div>
  );
};

export default connect(null, {})(TelegramButton);
